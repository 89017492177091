import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('@/views/Home')
    },
    {
      path: '/about-me',
      name: 'About',
      component: () => import('@/views/About')
    },
    {
      path: '/my-work',
      name: 'Work',
      component: () => import('@/views/Work')
    },
    {
      path: '/contact-me',
      name: 'Contact',
      component: () => import('@/views/Contact')
    },
    {
      path: '/AutoDiploma',
      name: 'AutoDiploma',
      component: () => import('@/views/AutoDiploma')
    },
    {
      path: '/fortigoDiploma',
      name: 'fortigoDiploma',
      component: () => import('@/views/fortigoDiploma')
    },
    {
      path: '/leofDiploma',
      name: 'leofDiploma',
      component: () => import('@/views/leofDiploma')
    },
    {
      path: '/ntalikaDiploma',
      name: 'ntalikaDiploma',
      component: () => import('@/views/ntalikaDiploma')
    },
    {
      path: '/taxiDiploma',
      name: 'taxiDiploma',
      component: () => import('@/views/taxiDiploma')
    },
    {
      path: '/epagelmatikoDiploma',
      name: 'epagelmatikoDiploma',
      component: () => import('@/views/epagelmatikoDiploma')
    },
    {
      path: '/amDiploma',
      name: 'amDiploma',
      component: () => import('@/views/amDiploma')
    },
    {
      path: '/a1Diploma',
      name: 'a1Diploma',
      component: () => import('@/views/a1Diploma')
    },
    {
      path: '/a2Diploma',
      name: 'a2Diploma',
      component: () => import('@/views/a2Diploma')
    },
    {
      path: '/aDiploma',
      name: 'aDiploma',
      component: () => import('@/views/aDiploma')
    },
    {
      path: '/sideServices/ananewsh',
      name: 'ananewsh',
      component: () => import('@/views/sideServices/ananewsh')
    },
    {
      path: '/sideServices/adeia_kykloforias',
      name: 'adeia_kykloforias',
      component: () => import('@/views/sideServices/adeia_kykloforias')
    },
    {
      path: '/sideServices/antikatastash',
      name: 'antikatastash',
      component: () => import('@/views/sideServices/antikatastash')
    },
    {
      path: '/sideServices/metatroph_kseni_xora',
      name: 'metatroph_kseni_xora',
      component: () => import('@/views/sideServices/metatroph_kseni_xora')
    },
    {
      path: '/sideServices/metatropi_elvetia',
      name: 'metatropi_elvetia',
      component: () => import('@/views/sideServices/metatropi_elvetia')
    },
    {
      path: '/sideServices/metekpedeush',
      name: 'metekpedeush',
      component: () => import('@/views/sideServices/metekpedeush')
    },
    {
      path: '/sideServices/mh_xorhghsh_pei',
      name: 'mh_xorhghsh_pei',
      component: () => import('@/views/sideServices/mh_xorhghsh_pei')
    },
    {
      path: '/sideServices/nea_adeia_kloph_apwleia',
      name: 'nea_adeia_kloph_apwleia',
      component: () => import('@/views/sideServices/nea_adeia_kloph_apwleia')
    },
    {
      path: '/sideServices/psifiakos_taxografos',
      name: 'psifiakos_taxografos',
      component: () => import('@/views/sideServices/psifiakos_taxografos')
    },
    {
      path: '/sideServices/seso',
      name: 'seso',
      component: () => import('@/views/sideServices/seso')
    },
    {
      path: '/sideServices/testdriveme',
      beforeEnter () { location.href = 'https://www.testdriveme.gr/' },
      name: 'online_test'
    },
    {
      path: '/sideServices/prosorino_diploma',
      beforeEnter () { location.href = 'https://drivers.services.gov.gr/login' },
      name: 'prosorino_diploma'
    },
    {
      path: '/vivlia',
      name: 'vivlia',
      component: () => import('@/views/sideServices/vivlia')
    },
    {
      path: '/sideServices/simvevlimenoi_iatroi',
      beforeEnter () { location.href = 'http://www.patt.gov.gr/site/index.php?option=com_content&view=article&id=3020&Itemid=232' },
      name: 'simvevlimenoi_iatroi'
    },
    {
      path: '/sideServices/xorigisi_paravolou',
      beforeEnter () { location.href = 'https://www1.gsis.gr/sgsisapps/eparavolo/public/welcome.htm' },
      name: 'xorigisi_paravolou'
    }
  ]
})

// Bootstrap Analytics
// Set in .env
// https://github.com/MatteoGabriele/vue-analytics
if (process.env.VUE_APP_GOOGLE_ANALYTICS) {
  Vue.use(require('vue-analytics').default, {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS,
    router,
    autoTracking: {
      page: process.env.NODE_ENV !== 'development'
    }
  })
}

export default router
